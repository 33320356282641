import React from "react";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Projects from "./components/Projects";
import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import Summary from "./components/Summary";
import data from "./data/data.json";
import Footer from "./components/Footer";
import "./styles/MainContent.css";
import "./styles/App.css";

const App = () => {
  return (
    <div className="app-container">
      <Navbar />
      <main className="main-content">
        <div className="content-section">
          <About about={data.about} />
          <Summary summary={data.summary} />
        </div>
        <div className="content-section">
          <Skills skills={data.skills} />
        </div>
        <div className="content-section">
          <Projects projects={data.projects} />
          <Portfolio portfolio={data.portfolio} />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default App;
