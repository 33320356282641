import React from "react";
import "../styles/Navbar.css";
import logo from "../assets/img/personal-website-logo.png";

const Navbar = () => {
  return (
    <nav className="navbar">
      <a href="#home" className="navbar-logo">
        <img src={logo} alt="Website Logo" className="logo-img" />
      </a>
      <ul className="navbar-list">
        <li className="navbar-item">
          <a href="#summary" className="navbar-link">
            About
          </a>
        </li>
        <li className="navbar-item">
          <a href="#skills" className="navbar-link">
            Skills
          </a>
        </li>
        <li className="navbar-item">
          <a href="#projects" className="navbar-link">
            Projects
          </a>
        </li>
        <li className="navbar-item">
          <a href="#portfolio" className="navbar-link">
            Portfolio
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
