import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import WOW from "wowjs";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "animate.css/animate.min.css";
import "../styles/Portfolio.css";
import ssspl from "../assets/img/ssspl.png";
import gtt from "../assets/img/gtt.png";

import { Navigation, Pagination } from "swiper/modules";

const Portfolio = ({ portfolio }) => {
  const images = {
    "SSS Powerlifting": ssspl,
    GTT: gtt,
  };

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <section
      id="portfolio"
      className="portfolio section wow animate__animated animate__fadeInUp"
    >
      <h2 className="section-title">Portfolio</h2>
      <span className="section-subtitle">Most recent work</span>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        centeredSlides={true}
        className="swiper"
      >
        {portfolio.map((project, index) => (
          <SwiperSlide
            key={index}
            className="swiper-slide wow animate__animated animate__fadeInUp"
          >
            <div className="portfolio-item">
              <div className="portfolio-content">
                <img
                  src={images[project.title]}
                  alt={project.title}
                  className="portfolio-img"
                />
                <div className="portfolio-text">
                  <h3 className="portfolio-title">{project.title}</h3>
                  <p className="portfolio-description">{project.description}</p>
                  <a
                    href={project.link}
                    className="portfolio-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website <i className="uil uil-arrow-right button-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Portfolio;
