import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-brand">
          <h2 className="footer-title">Michael Lebreck</h2>
          <p className="footer-subtitle">Software Developer</p>
        </div>
        <ul className="footer-links">
          <li>
            <a href="#summary">About</a>
          </li>
          <li>
            <a href="#skills">Skills</a>
          </li>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
        </ul>
        <div className="footer-social">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="uil uil-facebook-f"></i>
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="uil uil-instagram"></i>
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="uil uil-twitter-alt"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; 2024 Michael Lebreck. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
