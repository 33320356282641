import React, { useState, useEffect } from "react";
import WOW from "wowjs";
import "animate.css/animate.min.css";
import "../styles/Projects.css";

const Projects = ({ projects }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (projects && projects.length > 0) {
      setIsLoading(false);
      new WOW.WOW().init();
    }
  }, [projects]);

  const filteredProjects = projects.filter((project) =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section
      id="projects"
      className="projects wow animate__animated animate__fadeInUp"
    >
      <h2 className="section-title">Projects</h2>
      <div className="search">
        <input
          type="search"
          placeholder="Search Projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      {isLoading ? (
        <p>Loading projects...</p>
      ) : (
        <div className="projects-container">
          {filteredProjects.map((project, index) => (
            <article
              key={index}
              className="project-item wow animate__animated animate__fadeInUp"
            >
              <div className="project-content">
                <h3 className="project-title">{project.title}</h3>
                <p className="project-date">{project.date}</p>
                <ul className="project-description">
                  {project.description.map((desc, idx) => (
                    <li key={idx}>{desc}</li>
                  ))}
                </ul>
              </div>
            </article>
          ))}
        </div>
      )}
    </section>
  );
};

export default Projects;
