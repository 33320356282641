import React, { useEffect } from "react";
import WOW from "wowjs";
import "animate.css/animate.min.css";
import "../styles/Summary.css";

const Summary = ({ summary }) => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <section
      className="summary section wow animate__animated animate__fadeInUp"
      id="summary"
    >
      <h2 className="section__title">About me</h2>
      <span className="section__subtitle">Quick Overview</span>
      <div className="summary__container">
        <div className="summary__data">
          <p className="summary__description">{summary.description}</p>
          <div className="summary__info">
            {summary.info.map((item, index) => (
              <div key={index} className="summary__info-item">
                <span className="summary__info-title">{item.value}</span>
                <span className="summary__info-name">
                  {item.label} <br /> {item.subLabel}
                </span>
              </div>
            ))}
          </div>
          <div className="summary__buttons">
            <a
              download="resume.pdf"
              href="/assets/pdf/resume.pdf"
              className="button button--flex"
            >
              Download CV <i className="uil uil-download-alt button__icon"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Summary;
