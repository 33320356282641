import React, { useState, useEffect } from "react";
import WOW from "wowjs";
import "animate.css/animate.min.css";
import "../styles/Skills.css";

const SkillsDropdown = ({ title, skills }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`skills__group ${isOpen ? "open" : ""}`}>
      <h3 className="skills__title" onClick={toggleDropdown}>
        {title}
      </h3>
      {isOpen && (
        <ul>
          {skills.map((skill, index) => (
            <li
              key={index}
              className="skills__item"
              style={{ "--delay": `${index * 0.3}s` }} // Consistent delay for all items
            >
              {skill}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const Skills = ({ skills }) => {
  const [isTechnicalOpen, setIsTechnicalOpen] = useState(false);

  const toggleTechnicalDropdown = () => {
    setIsTechnicalOpen(!isTechnicalOpen);
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    // Remove WOW.js classes after the initial animation
    const handleAnimationEnd = () => {
      document.querySelectorAll(".wow").forEach((element) => {
        element.classList.remove(
          "wow",
          "animate__animated",
          "animate__fadeInUp"
        );
      });
    };

    document.addEventListener("animationend", handleAnimationEnd);

    return () => {
      document.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);

  return (
    <section
      className="skills section wow animate__animated animate__fadeInUp"
      id="skills"
    >
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle">My technical level</span>
      <div className="skills__container container">
        <div className={`skills__group ${isTechnicalOpen ? "open" : ""}`}>
          <h3 className="skills__title" onClick={toggleTechnicalDropdown}>
            Technical Skills
          </h3>
          {isTechnicalOpen && (
            <div className="skills__subcontainer">
              <SkillsDropdown
                title="Frontend Skills"
                skills={skills.frontend}
              />
              <SkillsDropdown title="Backend Skills" skills={skills.backend} />
              <SkillsDropdown
                title="Analytical Skills"
                skills={skills.analytical}
              />
              <SkillsDropdown
                title="Programming Languages"
                skills={skills.programmingLanguages}
              />
            </div>
          )}
        </div>
        <SkillsDropdown title="Soft Skills" skills={skills.softSkills} />
        <SkillsDropdown title="Tools & Frameworks" skills={skills.tools} />
      </div>
    </section>
  );
};

export default Skills;
